import React, { useEffect, useRef, useState } from "react";

import Layout from "../../layouts/LayoutDefault";
import Seo from "../../components/Seo";
import HomeIntro from "../../components/lp/how-to-sell-on-amazon/HomeIntro";
import RatingReviewTrustpilot from "../../components/lp/how-to-sell-on-amazon/RatingReviewTrustpilot";
import WhatYouNeedBlock from "../../components/lp/how-to-sell-on-amazon/WhatYouNeedBlock";
import EmailCollectBlock from "../../components/lp/how-to-sell-on-amazon/EmailCollectBlock";
import WhySellersPrefer from "../../components/lp/how-to-sell-on-amazon/WhySellersPrefer";
import Testimonials from "../../components/common/Testimonials";
import "../../components/lp/how-to-sell-on-amazon/index.scss";
import { isSavedUserInfo } from "../../helpers";
import PG_L_HTSOA_RATING_REVIEW_TRUSTPILOT_CONTENT from "../../components/lp/how-to-sell-on-amazon/RatingReviewTrustpilot/index.content";

const HowToSellOnAmazonPage = () => {
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const emailCollectRef = useRef(null);
  const { image, reviews1, reviews2 } =
    PG_L_HTSOA_RATING_REVIEW_TRUSTPILOT_CONTENT;
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  const handleScroll = () =>
    emailCollectRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  useEffect(() => {
    if (isSavedUserInfo()) {
      setIsSecondStateCollectEmail(true);
    }
    setIsLoading(false);
  }, []);

  return (
    <Layout
      isShowYoutubeModal={isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      customClass="PgLHTSOA"
    >
      <Seo
        title="Learn How to Sell on Amazon and Find the Best Products"
        description=""
        page="lp/how-to-sell-on-amazon"
        manifest="browserconfig.xml"
      />
      <HomeIntro
        isSecondStateCollectEmail={
          isLoading ? false : isSecondStateCollectEmail
        }
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
      <RatingReviewTrustpilot reviews={reviews1} image={image} />
      <WhatYouNeedBlock handleScroll={handleScroll} />
      <EmailCollectBlock
        isSecondState={isLoading ? false : isSecondStateCollectEmail}
        emailCollectRef={emailCollectRef}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
      <RatingReviewTrustpilot reviews={reviews2} image={image} />
      <WhySellersPrefer />
      <Testimonials setUrlYoutubeModal={setUrlYoutubeModal} />
    </Layout>
  );
};

export default HowToSellOnAmazonPage;
