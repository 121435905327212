import React from "react";
import EmailCollectContainer from "./EmailCollectContainer";
import PropTypes from "prop-types";
import ControlsEmailCollect from "../../../components/common/Forms/ControlsEmailCollect";

const ControlsEmailCollectContainer = ({
  pageAuthState,
  updatePageAuthState,
  btnText,
  btnSize,
  position,
  smallGreyBottomText,
  page,
  setIsShowText,
}) => {
  const handleSuccess = () => {
    setIsShowText(true);
  };
  return (
    <EmailCollectContainer
      page={page}
      pageAuthState={pageAuthState}
      updatePageAuthState={updatePageAuthState}
      onSuccess={handleSuccess}
    >
      <ControlsEmailCollect
        btnText={btnText}
        btnSize={btnSize}
        position={position}
        smallGreyBottomText={smallGreyBottomText}
      />
    </EmailCollectContainer>
  );
};

ControlsEmailCollectContainer.propTypes = {
  pageAuthState: PropTypes.bool,
  updatePageAuthState: PropTypes.func,
  btnText: PropTypes.string,
  btnSize: PropTypes.string,
  position: PropTypes.string,
  smallGreyBottomText: PropTypes.string,
  page: PropTypes.string,
  setIsShowText: PropTypes.func,
};

export default ControlsEmailCollectContainer;
